import React from 'react';
import { Flex, Loader } from '../ui';
import { useConfigurationAPI, AppAccessDeniedError, AppUnapprovedError } from '../api';
import TenantResources from './TenantResources';
import TenantSelection from './TenantSelection';
import ConfigurationError from './ConfigurationError';
import PermissionError from './PermissionError';

const Configuration = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isLoading, data, isError, error } = useConfigurationAPI().get();

  if (isLoading) {
    return (
      <Flex screen hAlign="center" vAlign="center">
        <Loader />
      </Flex>
    );
  }

  if (isError) {
    if (error instanceof AppAccessDeniedError) {
      return <ConfigurationError />;
    }

    if (error instanceof AppUnapprovedError) {
      return <PermissionError />;
    }

    return <ConfigurationError />;
  }

  // defaulting to empty in case no data is returned
  const fireflyWebAppTenants = data?.data.fireflyWebAppTenants || [];

  const isEmpty = fireflyWebAppTenants.length === 0;
  const isSingleTenant = fireflyWebAppTenants.length === 1;

  if (isEmpty) {
    return <ConfigurationError />;
  }

  return (
    <Flex gap="gap.medium" hAlign="start" vAlign="center">
      {isSingleTenant ? (
        <TenantResources tenantId={fireflyWebAppTenants[0].id} />
      ) : (
        <TenantSelection tenants={fireflyWebAppTenants} />
      )}
    </Flex>
  );
};

export default Configuration;
