import React from 'react';
import styled from 'styled-components';
import { TreeTitleProps } from '@fluentui/react-northstar';
import { Tree, ChevronEndIcon, ChevronDownIcon, FilesEmptyIcon } from '../ui';
import { TenantResource } from '../api';
import { useConfiguration } from './Context';

const TreeItem = styled.div`
  line-height: 0.9;
  padding-left: 10px;
  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
const Label = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  padding: 10px 20px 10px 10px;
`;

const Radio = styled.input`
  width: 17px;
  height: 17px;
  pointer-events: none;
`;

const Span = styled.span`
  display: inline-block;
  padding: 12px 20px 12px 10px;
`;

const iconStyles = { display: 'flex', alignItems: 'center' };

const getTreeItems = (items: TenantResource[]): any =>
  items.map((item: TenantResource) => ({
    title: item.displayName,
    id: item.isSelectable ? item.url : `${item.displayName}${item.id}`,
    items: item.children?.length ? getTreeItems(item.children) : [],
  }));

type ResourcesListProps = {
  resources: TenantResource[];
};

const ResourcesList = ({ resources }: ResourcesListProps) => {
  const { setConfigurationResource } = useConfiguration();
  const items = getTreeItems(resources);

  const renderItemTitle = (Component: any, { content, expanded, hasSubtree, index, ...restProps }: TreeTitleProps) => {
    const Chevron = () => (expanded ? <ChevronDownIcon styles={iconStyles} /> : <ChevronEndIcon styles={iconStyles} />);
    const { id } = restProps;
    // This is the only way at the moment to achieve this
    // TODO: Check if @fluentui Tree component updates will allow to pass data to tree items
    const selectable = id!.match(/(http(s)?:\/\/.)/);

    return (
      <TreeItem>
        <Component
          {...restProps}
          expanded={expanded}
          hasSubtree={hasSubtree}
          styles={{ paddingRight: '0', paddingLeft: '0', display: 'flex' }}
        >
          {hasSubtree ? <Chevron /> : <FilesEmptyIcon styles={iconStyles} />}
          {selectable ? (
            <Label htmlFor={`resource-${id}`}>
              {content}
              <Radio
                id={`resource-${id}`}
                name="resource"
                type="radio"
                onChange={() => setConfigurationResource(id!)}
              />
            </Label>
          ) : (
            <Span>{content}</Span>
          )}
        </Component>
      </TreeItem>
    );
  };

  return (
    <Tree
      styles={{
        paddingLeft: 0,
        width: '100%',
      }}
      items={items}
      renderItemTitle={renderItemTitle}
    />
  );
};

export default ResourcesList;
