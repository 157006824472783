import React from 'react';
import ConfigurationContextProvider from './Context';
import Configuration from './Configuration';

const ConfigurationScreen = () => (
  <ConfigurationContextProvider>
    <Configuration />
  </ConfigurationContextProvider>
);

export default ConfigurationScreen;
