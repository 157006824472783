/* eslint-disable max-classes-per-file */
export class ApiError extends Error {
  constructor(message: string, public code: number | string) {
    super(`Api error: ${message}`);
  }
}

export type SuccessResponse<TData> = {
  status: 'success';
  data: TData;
};

// CONFIGURATION

export type WebAppTenant = {
  id: string;
  domainName: string;
  displayName: string;
};

export type ConfigurationGetSuccessResponse = SuccessResponse<{
  fireflyWebAppTenants: WebAppTenant[];
}>;

// see https://fireflylearning.atlassian.net/browse/REA-2324
export class AppAccessDeniedError extends ApiError {
  constructor() {
    super('App access denied, feature flag not enabled', 'b4bfb72d-ced8-4db2-857b-d83f5c26a00d');
  }
}
export class AppUnapprovedError extends ApiError {
  constructor() {
    super(
      'Application is not approved to access school data, consent not granted',
      'd5d5fee0-925b-49c3-9c9b-dec77f48f5ea',
    );
  }
}

// RESOURCE
export type TenantResource = {
  id: string;
  isSelectable: boolean;
  url: string;
  displayName: string;
  children?: TenantResource[];
};

export type ResourcesGetSuccessResponse = SuccessResponse<TenantResource[]>;
