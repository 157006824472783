import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Flex, Loader } from '../ui';

type FireflyIframeMessage = {
  type: 'navigation';
};

const Resource = () => {
  const [loading, setLoading] = useState(true);

  // Process resource url
  const { search } = useLocation();
  const { resource } = qs.parse(search);
  const { url, query } = qs.parseUrl(`${resource}`);
  query.isTeams = 'true';

  const iframeSrc = qs.stringifyUrl({ url, query });
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const onIframeLoad = () => {
    iframeRef.current?.contentWindow?.postMessage({ type: 'renderTeams' }, '*');
    setLoading(false);
  };

  const onMessage = (e: MessageEvent<FireflyIframeMessage>) => {
    if (e.data.type === 'navigation') {
      setLoading(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return (
    <div style={{ position: 'absolute', width: '100%', height: '100%', overflow: 'hidden' }}>
      {loading && (
        <Flex screen hAlign="center" vAlign="center">
          <Loader />
        </Flex>
      )}
      <iframe
        title="firefly-resource"
        ref={iframeRef}
        onLoad={onIframeLoad}
        src={iframeSrc}
        frameBorder="0"
        height="100%"
        width="100%"
      />
    </div>
  );
};

export default Resource;
