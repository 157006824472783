import React from 'react';
import configError from '../../assets/images/config-error.png';
import { Flex, Text } from '../ui';

const ConfigurationError = () => (
  <Flex screen hAlign="center" vAlign="center" gap="gap.large">
    <img style={{ maxWidth: '250px' }} src={configError} alt="error" />
    <Flex gap="gap.small">
      <div>
        <Text weight="bold" size="large">
          You need a Firefly account to use the Firefly Curriculum app
        </Text>
      </div>
      <div>
        <Text>
          Firefly Curriculum is an app designed for schools using Firefly. You will need an account with a school using
          Firefly to use this app.
        </Text>
      </div>
      <div>
        <Text>
          If you’re interested in using Firefly for your school, please do{' '}
          <a
            style={{ textDecoration: 'none', display: 'inline-block' }}
            href="https://www.fireflysolutions.co.uk/firefly-on-microsoft-teams"
          >
            <Text color="brand">get in touch</Text>
          </a>
          .
        </Text>
      </div>
      <div>
        <Text>If you believe you’re seeing this in error, please contact your school’s technical help.</Text>
      </div>
    </Flex>
  </Flex>
);

export default ConfigurationError;
