import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Flex, Loader } from '../ui';

const ConsentPopup = () => {
  const { search } = useLocation();

  useEffect(() => {
    const { resource } = qs.parse(search);
    const resourceUrl = new URL(`${resource}`);

    const encodedUrl = encodeURIComponent(
      `${resourceUrl.origin}/login/api/webgettoken?app=${process.env.REACT_APP_FIREFLYWEBAPP_APPID}&successURL=${process.env.REACT_APP_BASE_URL}/auth-end`,
    );
    const url = `${resourceUrl.origin}/login/login.aspx?prelogin=${encodedUrl}&kr=MSAADKeyRing`;

    window.location.assign(url);
  }, [search]);

  return (
    <Flex screen hAlign="center" vAlign="center">
      <Loader label="Authenticating..." />
    </Flex>
  );
};

export default ConsentPopup;
