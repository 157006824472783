/* eslint-disable import/prefer-default-export */
/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from 'react-query';
import { useAPI } from './Context';
import { ConfigurationGetSuccessResponse, ApiError, AppAccessDeniedError, AppUnapprovedError } from './types';

const configurationGetResponseErrors = new Map<number, new () => ApiError>([
  [400, AppAccessDeniedError],
  [401, AppUnapprovedError],
]);

export const useConfigurationAPI = () => {
  const request = useAPI();

  const get = (): Promise<ConfigurationGetSuccessResponse> =>
    request
      .get<ConfigurationGetSuccessResponse>('/configuration', {
        // adding specific status codes to allowed responses status as we'll deal with them in a dedicated way
        validateStatus: (status) => (status >= 200 && status < 300) || configurationGetResponseErrors.has(status),
      })
      .then(({ status, data }) => {
        const ConfigurationGetResponseError = configurationGetResponseErrors.get(status);

        // throwing expected error if status matches one of the scenarios defined
        if (ConfigurationGetResponseError) {
          throw new ConfigurationGetResponseError();
        }

        return data;
      });

  return {
    get: () =>
      useQuery(['configuration', 'get'], get, {
        staleTime: Infinity,
        retry: false,
      }),
  };
};
