import React, { useEffect, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { createGlobalStyle } from 'styled-components';
import {
  Provider,
  teamsV2Theme,
  teamsDarkV2Theme,
  teamsHighContrastTheme,
  mergeThemes,
} from '@fluentui/react-northstar';
import { Normalize } from 'styled-normalize';

const GlobalStyles = createGlobalStyle`
  body,
  html {
    height: 100%;
    width: 100%;
  }
`;

const themes = {
  dark: mergeThemes(teamsDarkV2Theme, { siteVariables: { bodyBackground: '#292929' } }),
  default: teamsV2Theme,
  contrast: teamsHighContrastTheme,
};

type Props = { children: React.ReactNode };

const UIProvider = ({ children }: Props) => {
  const [theme, setTheme] = useState<string>('default');
  useEffect(() => {
    microsoftTeams.registerOnThemeChangeHandler((selectedTheme) => setTheme(selectedTheme!));
    microsoftTeams.getContext((context) => {
      setTheme(context.theme!);
    });
  }, []);

  return (
    <Provider theme={themes[theme as keyof typeof themes]}>
      <Normalize />
      <GlobalStyles />
      {children}
    </Provider>
  );
};

export default UIProvider;
