import React, { useEffect } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { Flex, Loader } from '../ui';

const ClosePopup = () => {
  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.authentication.notifySuccess('success');
  }, []);

  return (
    <Flex screen hAlign="center" vAlign="center">
      <Loader label="Authenticating..." />
    </Flex>
  );
};

export default ClosePopup;
