import React from 'react';
import configError from '../../assets/images/config-error.png';
import { Flex, Text } from '../ui';

const PermissionError = () => (
  <Flex screen hAlign="center" vAlign="center" gap="gap.large">
    <img style={{ maxWidth: '250px' }} src={configError} alt="error" />
    <Flex gap="gap.small">
      <div>
        <Text>
          To connect Teams with your Firefly account, please contact Firefly support who will set this up for you:{' '}
          <a
            style={{ textDecoration: 'none', display: 'inline-block' }}
            href="mailto://support@fireflylearning.com"
          >
            <Text color="brand">support@fireflylearning.com</Text>
          </a>
          .
        </Text>
      </div>
    </Flex>
  </Flex>
);

export default PermissionError;