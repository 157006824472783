import React, { useEffect } from 'react';
import { useResourcesAPI, WebAppTenant } from '../api';
import { useConfiguration } from './Context';
import ResourcesList from './ResourcesList';
import { Flex, Loader, Alert, Text } from '../ui';

type TenantResourcesProps = {
  tenantId: WebAppTenant['id'] | null;
};

const TenantResources = ({ tenantId }: TenantResourcesProps) => {
  const { isLoading, isError, data } = useResourcesAPI().get(tenantId!);
  const { setConfigurationResource, setConfigurationTenant } = useConfiguration();

  const helpText = (
    <Text>
      These are the resources available in your school&apos;s Firefly website. Choose which resource will be starting
      point for members of the group.
    </Text>
  );

  useEffect(() => {
    setConfigurationTenant(tenantId);
    setConfigurationResource(null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return (
      <>
        {helpText}
        <Flex screen styles={{ minHeight: '79vh' }} hAlign="center" vAlign="center">
          <Loader label="Loading resources..." />
        </Flex>
      </>
    );
  }

  if (isError) {
    return (
      <>
        {helpText}
        <Flex screen styles={{ minHeight: '79vh' }} hAlign="center" vAlign="center">
          <Alert danger content="There was a problem retrieving these resources." />
        </Flex>
      </>
    );
  }

  return (
    <>
      {helpText}
      <ResourcesList resources={data!} />
    </>
  );
};

export default TenantResources;
