import React, { useState, useEffect } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { useLocation } from 'react-router-dom';
import { Flex, Loader, Alert } from '../ui';

const Authentication = ({ children }: { children: () => JSX.Element | null }) => {
  const [error, setError] = useState('');
  const [authSuccess, setAuthSuccess] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.authentication.authenticate({
      url: `${process.env.REACT_APP_BASE_URL}/auth-start${search}`,
      width: 600,
      height: 535,
      successCallback: () => {
        setAuthSuccess(true);
        setError('');
      },
      failureCallback: (reason: string) => {
        setError(reason ?? '');
      },
    });
  }, [search]);

  if (error === 'FailedToOpenWindow') {
    return (
      <Flex screen hAlign="center" vAlign="center">
        <Alert
          warning
          content="Popups need to be enabled to use this app in the browser. Ask your IT admin how to do this, or use the desktop
          app instead."
        />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex screen hAlign="center" vAlign="center">
        <Alert danger content={`There was a problem authenticating you. Error: ${error}`} />
      </Flex>
    );
  }

  if (!authSuccess)
    return (
      <Flex screen hAlign="center" vAlign="center">
        <Loader label="Authenticating..." />
      </Flex>
    );

  return children();
};

export default Authentication;
