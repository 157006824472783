import React from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Route, Router } from '../lib/router';
import { reactPlugin } from '../lib/insights';
import APIProvider from './api';
import ConfigurationScreen from './configuration';
import ResourceScreen from './resource';
import ClosePopup from './teams/ClosePopup';
import ConsentPopup from './teams/ConsentPopup';
import { UIProvider } from './ui';
import Authentication from './teams/Authentication';

export const routes = {
  tab: '/tab',
  authStart: '/auth-start',
  authEnd: '/auth-end',
  config: '/config',
};

const App = () => (
  <UIProvider>
    <APIProvider>
      <Router>
        <Route exact path={routes.tab}>
          <Authentication>{() => <ResourceScreen />}</Authentication>
        </Route>
        <Route component={ConfigurationScreen} exact path={routes.config} />
        <Route component={ConsentPopup} exact path={routes.authStart} />
        <Route component={ClosePopup} exact path={routes.authEnd} />
      </Router>
    </APIProvider>
  </UIProvider>
);

export default withAITracking(reactPlugin, App);
