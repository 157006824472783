import React, { useState } from 'react';
import { WebAppTenant } from '../api';
import { RadioGroup, Button, ArrowLeftIcon, Text, ArrowRightIcon } from '../ui';
import { useConfiguration } from './Context';
import TenantResources from './TenantResources';

type TenantListProps = {
  tenants: WebAppTenant[];
};

const TenantSelection = ({ tenants }: TenantListProps) => {
  const [selectedTenant, selectTenant] = useState<WebAppTenant['id'] | null>(null);
  const { setConfigurationTenant, tenant } = useConfiguration();

  const items = tenants.map((t: WebAppTenant) => ({
    key: t.id,
    name: 'tenant',
    label: t.displayName,
    value: t.id,
  }));

  const onCheckedValueChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, data?: any) =>
    selectTenant(data!.value);

  if (!tenant)
    return (
      <>
        <Text>Choose which of your school&apos;s Firefly sites to add a resource from:</Text>
        <RadioGroup
          vertical
          items={items}
          defaultCheckedValue={selectedTenant || ''}
          onCheckedValueChange={onCheckedValueChange}
        />
        <Button
          disabled={!selectedTenant}
          icon={<ArrowRightIcon size="small" />}
          iconPosition="after"
          content="Next"
          onClick={() => setConfigurationTenant(selectedTenant)}
        />
      </>
    );

  return (
    <>
      <Button
        onClick={() => setConfigurationTenant(null)}
        icon={<ArrowLeftIcon size="small" />}
        text
        content="Select Firefly site"
      />
      <TenantResources tenantId={tenant} />
    </>
  );
};

export default TenantSelection;
