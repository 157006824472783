import React, { createContext, useContext, useState, useEffect } from 'react';
import { setConfigurationValidity, onConfigurationSave, setConfigurationSettings } from '../../lib/msteams';
import { WebAppTenant, TenantResource } from '../api';

type ConfigurationContext = {
  tenant: WebAppTenant['id'] | null;
  resource: TenantResource['url'] | null;
  setConfigurationTenant: (tenantId: WebAppTenant['id'] | null) => void;
  setConfigurationResource: (resourcesUrl: TenantResource['url'] | null) => void;
};

const Context = createContext<ConfigurationContext>({
  tenant: null,
  resource: null,
  setConfigurationTenant: () => {},
  setConfigurationResource: () => {},
});

export const useConfiguration = () => useContext(Context);

type ConfigurationState = {
  tenant: WebAppTenant['id'] | null;
  resource: TenantResource['url'] | null;
};

type Props = {
  children: React.ReactNode;
};

const ConfigurationContextProvider = ({ children }: Props) => {
  const [configuration, setConfiguration] = useState<ConfigurationState>({
    tenant: null,
    resource: null,
  });
  const setConfigurationTenant = (tenantId: WebAppTenant['id'] | null) => {
    setConfiguration((prevState) => ({ ...prevState, tenant: tenantId, resource: null }));
  };
  const setConfigurationResource = (resourcesUrl: TenantResource['url'] | null) => {
    setConfiguration((prevState) => ({ ...prevState, resource: resourcesUrl }));
  };

  useEffect(() => {
    const validConfiguration = Boolean(configuration.tenant && configuration.resource);

    // updating save configuration btn
    setConfigurationValidity(validConfiguration);

    // updating callback to persist current configuration on save
    onConfigurationSave(() => {
      if (!validConfiguration) return Promise.reject(new Error('Invalid configuration'));

      const contentUrl = `${
        process.env.REACT_APP_BASE_URL
      }/tab?tenant=${configuration.tenant!}&resource=${configuration.resource!}`;

      const entityId = contentUrl;
      const websiteUrl = contentUrl;

      return setConfigurationSettings({ contentUrl, websiteUrl, entityId });
    });
  }, [configuration]);

  const configurationContext = {
    tenant: configuration.tenant,
    resource: configuration.resource,
    setConfigurationTenant,
    setConfigurationResource,
  };

  return <Context.Provider value={configurationContext}>{children}</Context.Provider>;
};

export default ConfigurationContextProvider;
