import React from 'react';
import { Flex as MSFlex, FlexProps as MSFlexProps } from '@fluentui/react-northstar';

interface FlexProps extends MSFlexProps {
  screen?: boolean;
}

const Flex = ({ children, screen, styles, ...props }: FlexProps) => {
  const customStyles = { width: '100%', height: '100%', minHeight: screen ? '100vh' : 'auto', ...styles };

  return (
    <MSFlex column styles={customStyles} {...props}>
      {children}
    </MSFlex>
  );
};

export default Flex;
