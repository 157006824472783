/* eslint-disable import/prefer-default-export */
/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from 'react-query';
import { useAPI } from './Context';
import { WebAppTenant, ResourcesGetSuccessResponse } from './types';

export const useResourcesAPI = () => {
  const request = useAPI();

  const getResources = (tenantId: WebAppTenant['id']) =>
    request.get<ResourcesGetSuccessResponse>(`/${tenantId}/resources`).then(({ data: { data } }) => data);

  return {
    get: (tenantId: WebAppTenant['id']) =>
      useQuery(['resources', tenantId], () => getResources(tenantId), {
        staleTime: Infinity,
      }),
  };
};
