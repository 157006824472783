import { createContext, useContext } from 'react';
import axios, { AxiosStatic, AxiosInstance } from '../../lib/axios';

interface IAPIContext {
  request: AxiosStatic | AxiosInstance;
}

const APIContext = createContext<IAPIContext>({
  request: axios,
});

export const useAPI = () => useContext(APIContext).request;

export default APIContext;
