import React, { useEffect, useReducer, useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getAuthToken } from '../../lib/msteams';
import axios from '../../lib/axios';
import { Flex, Loader } from '../ui';
import APIContext from './Context';
import PermissionError from '../configuration/PermissionError';

enum APIStatus {
  idle = 'idle',
  pending = 'pending',
  success = 'success',
  error = 'error',
}

type APIState =
  | {
      status: APIStatus.idle | APIStatus.pending;
      authToken: undefined;
      error: undefined;
    }
  | {
      status: APIStatus.success;
      authToken: string;
      error: undefined;
    }
  | {
      status: APIStatus.error;
      authToken: undefined;
      error: string;
    };

type APIAction =
  | { type: APIStatus.pending }
  | {
      type: APIStatus.success;
      authToken: string;
    }
  | {
      type: APIStatus.error;
      error: string;
    };

const APIReducer = (state: APIState, action: APIAction): APIState => {
  switch (action.type) {
    case APIStatus.pending:
      return { status: APIStatus.pending, authToken: undefined, error: undefined };
    case APIStatus.success:
      return { status: APIStatus.success, authToken: action.authToken, error: undefined };
    case APIStatus.error:
      return { status: APIStatus.error, authToken: undefined, error: action.error };
    default:
      return state;
  }
};

type Props = {
  children: React.ReactNode;
};

const APIContextProvider = ({ children }: Props) => {
  const request = useRef(axios.create());
  const queryClient = useRef(new QueryClient());
  const [{ status }, dispatch] = useReducer(APIReducer, {
    status: APIStatus.idle,
    authToken: undefined,
    error: undefined,
  });

  useEffect(() => {
    dispatch({ type: APIStatus.pending });
    getAuthToken()
      .then((MSTeamsAuthToken) => {
        // setting default bearer auth token for every request
        request.current.defaults.headers.Authorization = `Bearer ${MSTeamsAuthToken}`;
        return dispatch({ type: APIStatus.success, authToken: MSTeamsAuthToken });
      })
      .catch((MSTeamsError: Error) => {
        dispatch({ type: APIStatus.error, error: MSTeamsError.message });
      });
  }, []);

  switch (status) {
    case APIStatus.idle:
      return null;
    case APIStatus.pending:
      return (
        <Flex screen hAlign="center" vAlign="center">
          <Loader />
        </Flex>
      );
    case APIStatus.error:
      return <PermissionError />;
    case APIStatus.success:
      return (
        <APIContext.Provider value={{ request: request.current }}>
          <QueryClientProvider client={queryClient.current}>{children}</QueryClientProvider>
        </APIContext.Provider>
      );
    default:
      return null;
  }
};

export default APIContextProvider;
