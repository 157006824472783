import * as microsoftTeams from '@microsoft/teams-js';

export const initialise = (callback?: () => void) => {
  microsoftTeams.initialize(callback);
};

export const getAuthToken = (): Promise<string> =>
  new Promise((resolve, reject) => {
    initialise(() => {
      microsoftTeams.authentication.getAuthToken({
        successCallback(result) {
          resolve(result);
        },
        failureCallback(error) {
          reject(new Error(error));
        },
      });
    });
  });

export const setConfigurationValidity = (validity: boolean) => {
  initialise(() => {
    microsoftTeams.settings.setValidityState(validity);
  });
};

export const onConfigurationSave = (callback: (event: microsoftTeams.settings.SaveEvent) => unknown) => {
  initialise(() => {
    microsoftTeams.settings.registerOnSaveHandler(async (saveEvent) => {
      try {
        await callback(saveEvent);
        saveEvent.notifySuccess();
      } catch (error: any) {
        saveEvent.notifyFailure(error);
        console.error(error); // eslint-disable-line no-console
      }
    });
  });
};

export const setConfigurationSettings = (settings: microsoftTeams.settings.Settings): Promise<void> =>
  new Promise((resolve, reject) => {
    initialise(() => {
      microsoftTeams.settings.setSettings(settings, (success, error) => {
        if (!success) reject(error);

        resolve();
      });
    });
  });

export const getContext = (): Promise<microsoftTeams.Context> =>
  new Promise((resolve) => {
    initialise(() => {
      microsoftTeams.getContext((msTeamsContext) => {
        resolve(msTeamsContext);
      });
    });
  });
